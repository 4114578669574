import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/runner/work/community-portal/community-portal/src/modules/layouts/mdx_layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Checklist = makeShortcode("Checklist");
const Callout = makeShortcode("Callout");
const Link = makeShortcode("Link");
const Column = makeShortcode("Column");
const Box = makeShortcode("Box");
const List = makeShortcode("List");
const Tout = makeShortcode("Tout");
const Button = makeShortcode("Button");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Hacking with Maker`}</h1>
    <h2>{`Developer Resources`}</h2>
    <p>{`Any project may seem complicated at first. This guide will help you get started with MakerDAO. The most up-to-date documentation and all the open-source tools within will help you hack together something genuinely valuable.`}</p>
    <h2>{`What Should I Bring?`}</h2>
    <h3>{`In-Person Hackathons`}</h3>
    <p>{`Hackathons can last several days, and there are loads of people working on projects around the clock. These events can be a lot to make sense of if you've never been to one before.`}</p>
    <p>{`We put together a list of `}<strong parentName="p">{`non-obvious essentials`}</strong>{` to help you get ready.`}</p>
    <Checklist mdxType="Checklist">
      <p>{`Notebook | Scratch Paper `}</p>
      <p>{`Pen | Pencil`}</p>
      <p>{`Toothbrush `}</p>
      <p>{`Toothpaste`}</p>
      <p>{`Deodorant`}</p>
      <p>{`Sleeping Bag`}</p>
      <p>{`Blanket`}</p>
      <p>{`Pillow`}</p>
      <p>{`Sweater | Jacket`}</p>
      <p>{`Umbrella | Raincoat`}</p>
      <p>{`Extra Clothes (for longer events)`}</p>
      <p>{`Good Vibes`}</p>
    </Checklist>
    <h3>{`Virtual Hackathons`}</h3>
    <p>{`With the current global climate, virtual events have gained popularity. For a virtual hackathon, you don't need to bring anything, but familiarizing yourself with MakerDAO beforehand is a great way to get a headstart. That way, you can spend more time building!`}</p>
    <Callout mdxType="Callout">
      <p>{`We recommend joining our `}<Link to="https://chat.makerdao.com/channel/community-development" icon="rocketchat" mdxType="Link">{`Community Chat`}</Link>{` and asking questions either in the  `}<Link to="https://chat.makerdao.com/channel/dev" mdxType="Link">{`#dev`}</Link>{` or `}<Link to="https://chat.makerdao.com/channel/community-development" mdxType="Link">{`#community-development`}</Link>{` channels to get ahead.`}</p>
    </Callout>
    <h2>{`Start Hacking`}</h2>
    <p>{`The two sections below contain links to what you should be trying to `}<em parentName="p">{`Hack`}</em>{`.`}</p>
    <Column mdxType="Column">
      <Box mdxType="Box">
        <h3>{`Protocol`}</h3>
        <List mdxType="List">
          <Link to="https://docs.makerdao.com" mdxType="Link">
            <p>{`Maker Docs`}</p>
            <p>{`Smart Contract & Protocol Level Documentation`}</p>
          </Link>
          <Link to="https://awesome.makerdao.com/#developer-resources" mdxType="Link">
            <p>{`Curated List of Documentation, Guides, Tools, and APIs`}</p>
            <p>{`Maker Developer Resources`}</p>
          </Link>
        </List>
      </Box>
      <Box mdxType="Box">
        <h3>{`Frontend`}</h3>
        <List mdxType="List">
          <Link to="https://github.com/makerdao/dai-ui" mdxType="Link">
            <p>{`Dai UI`}</p>
            <p>{`Components & React-Based Recipes for Various Themes`}</p>
          </Link>
        </List>
      </Box>
    </Column>
    <h2>{`Best Practices`}</h2>
    <Box sx={{
      paddingX: 2
    }} mdxType="Box">
      <hr></hr>
      <ul>
        <li parentName="ul">
          <p parentName="li">{`Take advantage of the opportunity to find mentors. A more experienced guide can help you find up-to-date resources and suggest the latest, most efficient ways of building.`}</p>
        </li>
        <li parentName="ul">
          <p parentName="li">{`Set up a team ahead of time. Find a diverse group of people with various skills to round out your idea.`}</p>
        </li>
        <li parentName="ul">
          <p parentName="li">{`Hackathons are a meeting place for many kinds of developers. Don't be afraid to take the opportunity to make new friends who may be able to teach you something new. Everyone is nervous, and most of us are introverts. It's okay!`}</p>
        </li>
        <li parentName="ul">
          <p parentName="li">{`Download the latest software you need ahead of time. Don't rely on the WiFi. `}</p>
        </li>
        <li parentName="ul">
          <p parentName="li">{`Self-care is important. Get proper rest, and make sure to take breaks when you need to.`}</p>
        </li>
        <li parentName="ul">
          <p parentName="li">{`Balance work and play. Meet exciting people to meet and work on attractive, winning ideas. Everyone comes to have a good time!`}</p>
        </li>
        <li parentName="ul">
          <p parentName="li">{`Think of your project as having both core-features and nice-to-have features. Define the core features up-front, and add the nice-to-haves only after building out the main features.`}</p>
        </li>
        <li parentName="ul">
          <p parentName="li">{`Check and submit your hackathon's submission system at least an hour before the deadline. Most hackathons use a system like DevPost to keep the submission process running smoothly, but uploading can crash the last minute.`}</p>
        </li>
        <li parentName="ul">
          <p parentName="li">{`Polish up for presentations. Use this hackathon and its platform to build a portfolio for yourself.`}</p>
        </li>
      </ul>
    </Box>
    <h2>{`Post-Hackathon Best Practices`}</h2>
    <Box sx={{
      paddingX: 2
    }} mdxType="Box">
      <hr></hr>
      <ul>
        <li parentName="ul">
          <p parentName="li">{`Update your repository or project page to reflect the current status of your project.`}</p>
        </li>
        <li parentName="ul">
          <p parentName="li">{`Tweet about your project. There may be other developers interested in helping you continue to build out your hack.`}</p>
        </li>
        <li parentName="ul">
          <p parentName="li">{`Stay in touch with your teammates.`}</p>
        </li>
      </ul>
    </Box>
    <Box sx={{
      paddingX: 2
    }} mdxType="Box">
      <hr></hr>
    </Box>
    <Tout alt image mdxType="Tout">
      <Box mdxType="Box">
        <h2>{`Grants`}</h2>
        <p>{`The Maker Grants Program uses Hackathons as a way to find suitable candidates for development grants. Winners are often fast-tracked.`}</p>
        <p>{`We review all submissions, so sometimes, we may follow up on a unique project that wasn't necessarily a winner. Make contacting you easier for us by following the best practices above.`}</p>
        <Box sx={{
          marginBottom: 3
        }} mdxType="Box">
          <Button to="/funding/grants/" mdxType="Button">Grants</Button>
        </Box>
      </Box>
    </Tout>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      